import { Outlet } from 'react-router-dom';
import GlobalWrapper from './GlobalWrapper';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import MobileNavigation from './MobileNavigation';
import LoadUtilityClassesLast from './LoadUtilityClassesLast';
import Authorization from './Authorization';
import Modals from './Modals';

const Layout = () => {
  return (
    <>
      <Authorization />
      <GlobalWrapper>
        <Header />

        <Content>
          <Outlet />
        </Content>

        <Footer />

        <MobileNavigation />
        <LoadUtilityClassesLast />
      </GlobalWrapper>

      <Modals />
    </>
  );
};

export default Layout;
